import { Typography, Select, Row, Col, Form, DatePicker, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { toGetTotalUser } from "./APIs";
import moment from "moment";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Loading from "react-loading-bar";

import { toGetUsers } from "../Compte/Api";
const { RangePicker } = DatePicker;

const { Option } = Select;
const StatsUser = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [all, setAll] = useState([]);
  const [selected, setSelectedUser] = useState(-1);

  useEffect(() => {
    AppStore.setFirst(2);
    AppStore.setSecond(14);
    getData(
      moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
      moment(new Date(), "YYYY-MM-DD")
    );
    toGetUsers().then((users) => {
      setUsers(users);
    });
  }, []);

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  const getData = (start, end) => {
    setData([]);

    setLoading(true);

    toGetTotalUser(start, end).then((res) => {
      setAll(res);
      const grouped = groupBy(res, (p) => p.date);
      var results = [];
      grouped.forEach((element) => {
        if (selected == -1) {
          var total = element.reduce((a, b) => a + parseFloat(b.total), 0);
          results.push({ date: element[0].date, total: total.toFixed(0) });
        } else {
          var el = element.filter((e) => e.id == selected)[0];
          results.push({ date: el.date, total: el.total });
        }
      });
      setTimeout(() => {
        setData(results);
        setLoading(false);
      }, 500);
    });
  };

  const onChangeUsers = (option) => {
    setData([]);
    var id = -1;
    if (option != "0") {
      id = users.filter((el) => el.username == option)[0].id;
    }

    setSelectedUser(id);

    const grouped = groupBy(all, (p) => p.date);
    var res = [];
    grouped.forEach((element) => {
      if (id == -1) {
        var total = element.reduce((a, b) => a + parseFloat(b.total), 0);
        res.push({ date: element[0].date, total: total.toFixed(0) });
      } else {
        var el = element.filter((e) => e.id == id);
        if (el.length > 0) {
          res.push({ date: el[0].date, total: el[0].total });
        }
      }
    });
    setData(res);
  };

  return (
    <>
      <Loading show={loading} color="red" showSpinner={true} />

      <Row className="auto--custom-header">
        <Col span="8">
          <Typography.Title level={3}>
            Statistiques utilisateur
          </Typography.Title>
        </Col>
        <Col span="6">
          <Form.Item label="Date">
            <RangePicker
              onCalendarChange={(e, date) => getData(date[0], date[1])}
              defaultValue={[
                moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
                moment(new Date(), "YYYY-MM-DD"),
              ]}
              format={"YYYY-MM-DD"}
            />
          </Form.Item>
        </Col>
        <Col span="4">
          <Form.Item label="Utilisateur">
            <Select
              showSearch
              onSelect={onChangeUsers}
              style={{ width: "80%" }}
              defaultValue="0"
            >
              <Option value="0">Tout</Option>

              {users.map((u) => (
                <Option value={u.username}>{u.username}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Skeleton active loading={loading} />

      {data.length > 0 && (
        <Row>
          <Col span="24">
            <div style={{ height: 500, marginTop: "4vh" }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={data}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="date" />
                  <YAxis
                    type="number"
                    dataKey={(v) => parseInt(v.total)}
                    domain={[0, "dataMax+10"]}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="total"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default StatsUser;
