import {
  Button,
  Typography,
  Skeleton,
  Row,
  Col,
  Checkbox,
  Tooltip,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
} from "antd";
import { useEffect, useState } from "react";
import {
  toGetPurshaseOrders,
  toGetTotalPO,
  toPrintPurshaseOrder,
  toPrintPurshaseOrderWithEntete,
  toGetProviderList,
} from "../APIs";
import { Link } from "react-router-dom";
import {
  PrinterOutlined,
  InfoCircleTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import DataTable from "react-data-table-component";

import PurshaseOrderModal from "./PurshaseOrderModal";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/fr";
import Search from "../../../Utils/Search";
import { toGetUsers } from "../../Compte/Api";

import { customFilter, toConvertDate } from "../../../Utils/Utils";
const ls = require("localstorage-ttl");
const { Option } = Select;

moment.updateLocale("fr", localization);

const fields = [
  { name: "Référence", field: "ref", type: "varchar" },
  { name: "Total", field: "total", type: "number" },
  { name: "Fournisseur", field: "providerName", type: "varchar" },
  { name: "Remise", field: "discount", type: "number" },
  { name: "Nombre de produits", field: "numberArticle", type: "number" },
  { name: "Date de création", field: "createdDate", type: "date" },
];

const PurshaseOrdersList = ({ AppStore, ...props }) => {
  const [purshaseOrders, setPurshaseOrders] = useState([]);
  const [purshaseOrdersAll, setPurshaseOrdersAll] = useState([]);
  const [PurshaseOrderToInspect, setPurshaseOrderToInspect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [selectedMethode, setSelectedMethode] = useState(2);
  const [selectedProvider, setSelectedProvider] = useState("0");
  const [selectedDate, setSelectedDate] = useState(0);
  const [displayDate, setDisplayDate] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("0");
  const [selectedDateValue, setSelectedDateValue] = useState("0");
  const [providers, setProviders] = useState([]);
  const [show, setShow] = useState(true);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    AppStore.setFirst(0);
    AppStore.setSecond(1);
    var l__users = [];

    toGetUsers().then((users) => {
      console.log(users);

      setUsers(users);
    });

    if (null != ls.get("providers")) {
      setProviders(ls.get("providers"));
      setLoading(false);
    }
    toGetProviderList().then((res) => {
      setProviders(res);
      setLoading(false);
    });
    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  }, []);

  const printPurshaseOrder = (e, purshase) => {
    e.preventDefault();

    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }

    if (toVerifyIfChecked(purshase)) {
      toPrintPurshaseOrder(purshase.id, titre1);
    } else {
      toPrintPurshaseOrderWithEntete(purshase.id, titre1);
    }
  };

  const onSearch = (e) => {
    // setSearchValue(e.target.value);
    // const updatedList = customFilter(e.target.value, ordersAll, [
    //   "providerName",
    //   "total",
    //   "ref",
    //   "factureRef",
    //   "date",
    //   "blProvider",
    //   "paiedText",
    // ]);
    // setBonsLivraison(updatedList);

    setSearchValue(e);

    setLoading(true);

    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: e,
    });
  };
  const updatePurshaseOrder = (PurshaseOrder) => {
    const win = window.open(
      "/achat/purshaseOrder/modifier/" + PurshaseOrder.id,

      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  const toVerifyIfChecked = (purshase) => {
    var length = checkedItems.filter((element) => element.id == purshase.id)
      .length;
    if (length === 0) {
      return false;
    }
    return true;
  };

  const toCheck = (purshase) => {
    var length = checkedItems.filter((element) => element.id == purshase.id)
      .length;
    if (length === 0) {
      setCheckedItems([...checkedItems, purshase]);
    } else {
      let items = checkedItems.filter((element) => element.id !== purshase.id);
      setCheckedItems(items);
    }
  };

  const toUpdateData = (values) => {
    setPurshaseOrders(values);
    setSearchValue("");
    setFilterModalVisible(false);
  };

  const actualise = () => {
    setShow(true);
    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeCredit = (option) => {
    setSelectedMethode(option);
    fetchBRS(0, {
      paied: option,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeFournisseur = (option) => {
    var id = 0;

    if (option == "Tout") {
      id = 0;
    } else {
      id = providers.filter((el) => el.s__nom == option)[0].id;
    }
    setSelectedProvider(id);
    fetchBRS(0, {
      paied: selectedMethode,
      provider: id,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeUsers = (option) => {
    var id = "0";
    if (option != "0") {
      id = users.filter((el) => el.username == option)[0].id;
    }
    setSelectedUser(id);
    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: id,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeDate = (option) => {
    if (option == "5") {
      setSelectedDate(option);
      setDisplayDate(true);
      setDisplayDateRange(false);
      return;
    }
    if (option == "6") {
      setSelectedDate(option);
      setDisplayDateRange(true);
      setDisplayDate(false);
      return;
    }
    setDisplayDate(false);

    setSelectedDate(option);

    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: option,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const handlePageChange = (page) => {
    fetchBRS(page - 1, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  const fetchBRS = async (page, options) => {
    setLoading(true);
    setShow(true);
    setTotal(0);
    toGetPurshaseOrders(page, perPage, options).then((data) => {
      setPurshaseOrders(data.res);
      setLoading(false);
      setShow(false);
    });
    toGetTotalPO(options).then((data) => {
      setTotalRows(data.nb);
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setShow(true);

    toGetPurshaseOrders(page - 1, newPerPage, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    }).then((data) => {
      setPurshaseOrders(data.res);
      setShow(false);
    });

    setPerPage(newPerPage);
    setLoading(false);
  };

  const columns = [
    {
      name: "Référence",
      selector: "ref",
      sortable: true,
      format: (br) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setPurshaseOrderToInspect(br)}
        >
          {br.ref}
        </span>
      ),
    },

    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.25,

      format: (row) => `${toConvertDate(row.createdDate)}`,
    },

    {
      name: "Fournisseur",
      selector: "providerName",
      sortable: true,
      grow: 1.5,
    },

    {
      name: "Total",
      selector: "total",
      sortable: true,
      grow: 0.75,

      format: (row) => `${row.total.toFixed(3) + " DT"}`,
    },

    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 0.75,
    },

    {
      name: "Actions",
      selector: "id",

      grow: 0.75,

      format: (purshase) => (
        <td className="ant-table-cell" style={{ float: "right" }}>
          <Tooltip title="Pour imprimer sans entête" color="#2db7f5">
            <Checkbox onChange={() => toCheck(purshase)}></Checkbox>
          </Tooltip>
          <Button
            icon={<PrinterOutlined />}
            style={{ marginLeft: 5, marginRight: 1 }}
            onClick={(e) => printPurshaseOrder(e, purshase)}
            onContextMenu={(e) => printPurshaseOrder(e, purshase)}
          ></Button>

          <Button
            icon={<InfoCircleTwoTone />}
            style={{ marginRight: 1 }}
            onClick={() => {
              setPurshaseOrderToInspect(purshase);
            }}
          ></Button>
          <Button
            style={{ marginRight: 1 }}
            icon={<EditOutlined />}
            onClick={() => updatePurshaseOrder(purshase)}
          ></Button>
        </td>
      ),
    },
  ];

  return (
    <div id="achat">
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={5}>
              LISTE DES BONS DE COMMANDES
            </Typography.Title>
          </Col>
          <Col span="5" style={{ textAlign: "right" }} justify="end">
            <Form.Item label="Réf,note,fournisseur...">
              <Input.Search onSearch={onSearch} enterButton />
            </Form.Item>
          </Col>

          <Col span="4">
            <Form.Item label="Date">
              <Select
                disabled={show}
                showSearch
                onSelect={onChangeDate}
                style={{ width: "100%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                {/* <Option value="4">Cette année</Option>*/}
                <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && (
              <DatePicker
                disabled={show}
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBRS(0, {
                    paied: selectedMethode,
                    provider: selectedProvider,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
            {displayDateRange && (
              <DatePicker.RangePicker
                disabled={show}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBRS(0, {
                    paied: selectedMethode,
                    provider: selectedProvider,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
          </Col>

          <Col span="4">
            <Form.Item label="Fournisseur">
              <Select
                disabled={show}
                showSearch
                onSelect={onChangeFournisseur}
                style={{ width: "80%" }}
                defaultValue="Tout"
              >
                <Option value="Tout">Tout</Option>

                {providers.map((c) => (
                  <Option value={c.s__nom}>{c.s__nom}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Utilisateurs">
              <Select
                disabled={show}
                showSearch
                onSelect={onChangeUsers}
                style={{ width: "80%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                {users.map((u) => (
                  <Option value={u.username}>{u.username}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span="3" style={{ textAlign: "right" }} justify="end">
            <Input.Search onSearch={onSearch} enterButton />
          </Col> */}
          <Col span="2" style={{ textAlign: "right" }}>
            {/* <Button
              type="link"
              onClick={(e) => setFilterModalVisible(true)}
              icon={
                <img
                  src="/images/filterIconVente.svg"
                  style={{ width: "3vh", height: "3vh" }}
                />
              }
              style={{ marginRight: "5px" }}
            ></Button> */}
            <Link key="20" to="/achat/reception/creer">
              <Button
                type="link"
                size="large"
                icon={<img src="/images/plusVente.svg" />}
              ></Button>
            </Link>
            <Tooltip title="Actualiser la liste des brs">
              <Button
                onClick={() => actualise()}
                type="link"
                size="large"
                icon={<img src="/images/refresh.svg" />}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </Form>{" "}
      <Row>
        <Col span="24">
          <DataTable
            customStyles={customStyles}
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            persistTableHead
            noDataComponent={<span>Pas de bon de commande trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)",
            }}
            columns={columns}
            data={purshaseOrders}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Col>
      </Row>
      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search
          toUpdateData={toUpdateData}
          fields={fields}
          list={purshaseOrdersAll}
        />
      </Modal>
      <PurshaseOrderModal
        purshaseorder={PurshaseOrderToInspect}
        onClose={() => setPurshaseOrderToInspect(null)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPurshaseOrderToUpdate: (PurshaseOrder) =>
      dispatch(setItemsState(PurshaseOrder)),
  };
};

export default connect(null, mapDispatchToProps)(PurshaseOrdersList);
