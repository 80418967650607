import { Typography, Skeleton, Row, Col, Form, DatePicker } from "antd";
import { useEffect, useState } from "react";
import BonLivraisonModal from "../Vente/BL/BonLivraisonModal";
import { toGetTotalHT } from "./APIs";
import moment from "moment";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { toConvertDateTime } from "../../Utils/Utils";
const Objectif = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [blToInspect, setBlToInspect] = useState(null);

  useEffect(() => {
    AppStore.setFirst(2);
    AppStore.setSecond(12);
    getData(new Date());
  }, []);

  const toVerifyDate = (cnxDate) => {
    const cnx = moment(new Date(cnxDate));
    const selectedDate = moment(date).toDate();
    selectedDate.setHours(8);
    selectedDate.setMinutes(15);

    if (cnx.isBefore(selectedDate)) {
      return true;
    }
    return false;
  };

  const getData = (date) => {
    setLoading(true);
    setData([]);
    setDate(date);
    toGetTotalHT(date).then((res) => {
      setData(res);
      setLoading(false);
    });
  };

  const getBonus = (element) => {
    var total = element.total;

    var bonus = 0;
    if (total > 7500) {
      bonus += ((total - 7500) * 2) / 100 + 70;
    } else if (total > 4500) {
      bonus = ((total - 4500) * 1.5) / 100 + 25;
    } else if (total > 3500) {
      bonus = ((total - 3500) * 1) / 100 + 15;
    } else if (total > 2000 && toVerifyDate(element.date)) {
      bonus = ((total - 2000) * 0.5) / 100;
    }

    return bonus.toFixed(2);
  };

  return (
    <>
      <Row className="auto--custom-header">
        <Col span="8">
          <Typography.Title level={3}>OBJECTIFS</Typography.Title>
        </Col>
        <Col span="4">
          <Form.Item label="Date">
            <DatePicker
              style={{ width: "100%" }}
              onChange={(e, date) => {
                getData(date);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginTop: "1vh" }}>
        <Col span="3"></Col>
        <Col span="18">
          <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Utilisateur </th>
                  <th className="ant-table-cell">Total Vente HT</th>
                  <th className="ant-table-cell">Date dernière connexion</th>
                  <th className="ant-table-cell">BL(s) Non payé</th>
                  <th className="ant-table-cell">Bonus </th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <Skeleton active loading={loading} />
              <tbody className="ant-table-tbody">
                {data.map((element) => (
                  <tr
                    className={
                      element.total > 3500 && element.bls.length > 0
                        ? "auto--objectif-pass"
                        : element.total > 3500 &&
                          element.bls.length == 0 &&
                          "auto--objectif-done"
                    }
                  >
                    <td className="ant-table-cell">{element.username}</td>
                    <td className="ant-table-cell">{element.total}</td>
                    <td className="ant-table-cell">
                      {null != element.date
                        ? toConvertDateTime(element.date)
                        : "non connecté"}
                    </td>
                    <td className="ant-table-cell">
                      {element.bls.map((e) => (
                        <Typography.Link onClick={() => setBlToInspect(e.id)}>
                          {e.ref + "  **  "}
                        </Typography.Link>
                      ))}
                    </td>
                    <td className="ant-table-cell">
                      {/* {element.total > 3500 &&
                      element.bls.length == 0 &&
                      toVerifyDate(element.date)
                        ? "+10 dt"
                        : ""} */}

                      {getBonus(element)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
        <Col span="3"></Col>
      </Row>
      {data.length > 0 && (
        <Row>
          <Col span="24">
            <div style={{ height: 300 }}>
              <ResponsiveContainer>
                <BarChart
                  height={400}
                  data={data}
                  style={{ marginTop: "50px", marginBottom: "2vh" }}
                >
                  <XAxis dataKey="username" fontFamily="Proxima Nova"></XAxis>
                  <Tooltip cursor={{ fill: "transparent" }} />

                  <Bar
                    radius={[2, 2, 0, 0]}
                    dataKey="total"
                    barSize={200}
                    stackId="a"
                    fill="#846eb1"
                    fontFamily="Proxima Nova"
                  >
                    {data.map((entry, index) => (
                      <Cell></Cell>
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      )}
      <BonLivraisonModal
        entete={true}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
    </>
  );
};

export default Objectif;
