import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { loadUIConfig } from "./Actions/UIActions";
import Login from "./Pages/Login";
import { loadUser, logout } from "./Actions/AuthActions";
import { Button, Modal, Form, Input, notification as notifAntd } from "antd";
import cookie from "react-cookies";

import "./Styles/App.scss";
import TopNavLayout from "./Layouts/TopNavigationLayout";
import Admin from "./Pages/Admin";
import history from "./Utils/History";
import { getToken, onMessageListener } from "./Utils/firebase";
import { toGetBL, toSendNotification } from "./Pages/Vente/APIs";
import BonLivraisonModal from "./Pages/Vente/BL/BonLivraisonModal";
import { successMsg } from "./Utils/Utils";
import AppStore from "./Stores/AppStore";

function App(props) {
  const [isTokenFound, setTokenFound] = useState(null);
  getToken(setTokenFound);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [blToInspect, setBlToInspect] = useState(null);
  const [id, setId] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [notifText, setNotifText] = useState("");

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      openNotification(payload);
    })
    .catch((err) => console.log("failed: ", err));

  const close = (id) => {
    notifAntd.close(id);
    setBlToInspect(id);
  };

  const toOpenModal = (id) => {
    notifAntd.close(id);

    setOpenModal(true);
    setId(id);
  };

  const openNotification = (payload) => {
    const key = `open${Date.now()}`;
    const btn = (
      <>
        <Button
          type="primary"
          size="small"
          onClick={() => close(payload.data.id)}
        >
          Voir détail
        </Button>

        <Button
          type="primary"
          size="small"
          style={{ marginLeft: "2vh" }}
          disabled={payload.data.username != cookie.load("username")}
          onClick={() => toOpenModal(payload.data.id)}
        >
          Répondre
        </Button>
      </>
    );

    const args = {
      key: payload.data.id,
      message: payload.notification.title,
      description: payload.notification.body,
      duration: 0,
      btn,
      className:
        payload.data.username == cookie.load("username") && "auto--border-red",
    };
    notifAntd.error(args);
  };

  useEffect(() => {
    props.loadUIConfig();
    props.loadUser();
  }, []);

  const sendNotification = () => {
    var bls = [];

    bls.push(id);

    toSendNotification(notifText, bls).then(() => {
      successMsg("Notification envoyée avec succés");

      setOpenModal(false);
      setNotifText("");
      setId(-1);
    });
  };

  return props.loaded ? (
    <Router history={history}>
      <Switch>
        <Route path="/login">
          {null == props.user ? <Login></Login> : <Redirect to="/" />}
        </Route>
        <Route
          path=""
          exact={true}
          render={({ location }) => (
            <TopNavLayout AppStore={AppStore}>
              <Admin AppStore={AppStore} location={location} />
            </TopNavLayout>
          )}
        ></Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
      <Modal visible={openModal} title="Réponse" footer={[]}>
        <Form.Item>
          <Input
            value={notifText}
            onChange={(e) => setNotifText(e.target.value)}
          />
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={() => sendNotification()}>
            Envoyer
          </Button>
        </div>
      </Modal>
      <BonLivraisonModal
        entete={false}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
    </Router>
  ) : (
    <></>
  );
}

const mapStateToProps = ({ authState }) => {
  return {
    loaded: authState.loaded,
    user: authState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUIConfig: (config) => dispatch(loadUIConfig(config)),
    loadUser: () => dispatch(loadUser()),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
