import CAxios from "../../Utils/CAxios";

//Profile's api
export const toGetProfile = async () => {
  const { data } = await CAxios.get(`/api/profile`);
  return data.success && data.success.user ? data.success.user : [];
};

export const toGetUsers = async () => {
  const { data } = await CAxios.get(`/api/users/all`);
  return data.success && data.success.users ? data.success.users : [];
};

export const toDeleteUser = async (id) => {
  await CAxios.post(`/api/users/delete`, { id: id });
};

export const toRegisterUser = async (user) => {
  var l__url = `/api/users/register`;
  if (null != user.id) {
    l__url = `/api/users/update`;
  }
  const { data } = await CAxios.post(l__url, JSON.stringify(user));
  return data.success && data.success.user ? data.success.user : [];
};

export const toDeactivateUser = async (id) => {
  await CAxios.post(`/api/users/activate_deactivate`, { id: id });
};

export const toRegeneratePwd = async (id) => {
  await CAxios.post(`/api/users/regenerate`, { id: id });
};

export const toUpdateCredentials = async (username, password) => {
  await CAxios.post(`/api/users/update/credentials`, {
    username: username,
    password: password,
  });
};

export const toRegenerateCode = async (code) => {
  const { data } = await CAxios.post(`/api/parameters/regenerate`, {
    code: code,
  });
  return data.success && data.success.data ? data.success.data : [];
};

export const toAddOrUpdatePreferences = async (details) => {
  const { data } = await CAxios.post(
    `/api/preferences/update`,
    JSON.stringify(details)
  );
  return data.success && data.success.data ? data.success.data : [];
};

export const toGetPreferences = async (id) => {
  const { data } = await CAxios.post(`/api/preferences/get`, { id: id });
  return data.success && data.success.preferences
    ? JSON.parse(data.success.preferences)
    : [];
};

export const toUpdatePercentage = async (percentage) => {
  const { data } = await CAxios.post(`/api/percentage/update`, {
    percentage: percentage,
  });
  return data.success && data.success.percentage ? data.success.percentage : [];
};

export const toGetCode = async () => {
  const { data } = await CAxios.post(`/api/parameters/get`, {});
  return data.success && data.success.data ? data.success.data : [];
};

export const toVerifyCode = async (code) => {
  const { data } = await CAxios.post(`/api/parameters/verif`, { code: code });
  return data.success && data.success.valid ? data.success.valid : false;
};

//Entity's api
export const toGetEntityList = async () => {
  const { data } = await CAxios.get(`/api/entity/list`);
  return data.success && data.success.entitys ? data.success.entitys : [];
};

export const toDeleteEntity = async (id) => {
  await CAxios.post(`/api/entity/delete`, { id: id });
};

export const toAddOrUpdateEntity = async (title, id) => {
  var url = id != -1 ? "/api/entity/update" : "/api/entity/add";
  const { data } = await CAxios.post(url, { title: title, id: id });
  return data.success && data.success.entity ? data.success.entity : null;
};

export const toChangeDisplay = async (name, tel) => {
  await CAxios.post(`/api/display/change`, { name: name, tel: tel });
};
