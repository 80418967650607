import {
  Divider,
  Descriptions,
  Form,
  Modal,
  Row,
  Button,
  Empty,
  Col,
} from "antd";
import { useState, useEffect } from "react";
import ArticleHistory from "./ArticleHistory";
import TecdocLiaison from "./TecdocLiaison";
import { RiseOutlined } from "@ant-design/icons";
var Barcode = require("react-barcode");

const ArticleDetail = ({ article, visible, setDetailVisible }) => {
  const [form] = Form.useForm();
  const [tecdocLiaison, setTecdocLiaison] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState("");
  const [articleHistory, setArticleHistory] = useState(null);

  useEffect(() => {
    form.setFieldsValue(article);
  }, [article]);

  const cancelCreateModal = () => {
    setDetailVisible(false, article);
  };
  const afterBind = (j, json) => {
    article.data = json.info;
    article.titre = json.name;
    article.b__tecdoc = json.referenceTecdoc == "" ? false : true;
    form.setFieldsValue(article);

    setTecdocLiaison(false);
  };

  return (
    <>
      <Modal
        className="smtv-modal modal-large"
        title={
          article != "" && (
            <>
              {article.titre2}
              <img
                style={{ marginLeft: "2vh" }}
                onClick={() => {
                  setTecdocLiaison(true);
                  setSelectedArticle(article);
                }}
                src="/images/tecdoc.png"
                className={
                  article.b__tecdoc ? "auto-tecdoc-img" : "auto-tecdoc-img-gray"
                }
                alt="smtv-logo"
              />
            </>
          )
        }
        visible={visible}
        maskClosable={false}
        onCancel={(e) => cancelCreateModal(article)}
        footer={[
          <Button key="back" onClick={(e) => cancelCreateModal()}>
            Ok
          </Button>,
        ]}
        onOk={cancelCreateModal}
      >
        <div>
          <Row gutter={24}>
            <Col span="10">
              <Button
                type="primary"
                size="large"
                icon={<RiseOutlined />}
                onClick={(e) => setArticleHistory(article)}
              >
                Historique
              </Button>
            </Col>
            <Col span="8">
              {article.b__tecdoc && article.data.docId != "" ? (
                <img
                  src={`https://webservice.tecalliance.services/pegasus-3-0/documents/20197/${article.data.docId}`}
                />
              ) : (
                <Empty description="Aucune image disponible"></Empty>
              )}
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span="24">
              <Divider>Article détails</Divider>
              <Descriptions bordered size="small">
                <Descriptions.Item label="Titre" className="item-label">
                  {article.titre}
                </Descriptions.Item>
                <Descriptions.Item label="Titre 2">
                  {article.titre2}
                </Descriptions.Item>
                <Descriptions.Item label="Reférence">
                  {article.reference}
                </Descriptions.Item>
                <Descriptions.Item label="Code">
                  {article.code}
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                  {article.type}
                </Descriptions.Item>
                <Descriptions.Item label="Unite">
                  {article.unite}
                </Descriptions.Item>
                <Descriptions.Item label="Marque">
                  {article.marque}
                </Descriptions.Item>
                <Descriptions.Item label="Catégorie">
                  {article.categorie}
                </Descriptions.Item>
                <Descriptions.Item label="Emplacement">
                  {article.emplacement}
                </Descriptions.Item>
                <Descriptions.Item label="Seuil">
                  {article.seuil}
                </Descriptions.Item>
                <Descriptions.Item label="TVA">{article.tva}</Descriptions.Item>
              </Descriptions>
              <br />
              <br />
            </Col>
          </Row>
          <Divider>Prix</Divider>

          <Descriptions bordered size="default">
            <Descriptions.Item label="Prix achat HT">
              {article.prixAchatHT}
            </Descriptions.Item>
            <Descriptions.Item label="Prix achat TTC">
              {article.prixAchatTTC}
            </Descriptions.Item>
            <Descriptions.Item label="Marge">
              {article.marge + " " + article.margeValue}
            </Descriptions.Item>
            <Descriptions.Item label="Prix Vente HT">
              {article.prixVenteHT}
            </Descriptions.Item>
            <Descriptions.Item label="Prix Vente TTC">
              {article.prixVenteTTC}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <br />
          {article.b__tecdoc && (
            <>
              <Divider>Détails Tecdoc</Divider>

              <Descriptions column={2} bordered size="default">
                {article.data.details.map((element) => (
                  <Descriptions.Item label={element.name}>
                    {element.value}
                  </Descriptions.Item>
                ))}
              </Descriptions>
              <Divider>References d'origine</Divider>

              <Descriptions column={1} bordered size="default">
                {article.data.origine.map((element) => (
                  <Descriptions.Item label={element.name}>
                    {element.value}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </>
          )}
        </div>
      </Modal>

      {null != articleHistory && (
        <ArticleHistory
          setArticleHistory={() => setArticleHistory(null)}
          article={articleHistory}
          visible={articleHistory != null}
        />
      )}

      <Modal
        className="smtv-modal modal-large"
        visible={tecdocLiaison}
        maskClosable={false}
        title={selectedArticle.titre2 + " " + selectedArticle.reference}
        onCancel={(e) => setTecdocLiaison(false)}
        footer={[
          <Button key="back" onClick={(e) => setTecdocLiaison(false)}>
            Fermer
          </Button>,
        ]}
      >
        <TecdocLiaison
          selectedArticle={selectedArticle}
          setTecdocLiaison={(j, json) => afterBind(j, json)}
        />
      </Modal>
    </>
  );
};
export default ArticleDetail;
