import {
  Typography,
  Skeleton,
  Row,
  Col,
  Form,
  DatePicker,
  Select,
  Descriptions,
} from "antd";
import { useEffect, useState } from "react";
import BonLivraisonModal from "../Vente/BL/BonLivraisonModal";
import { toGetTotalTTC } from "./APIs";
import moment from "moment";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { toGetEntityList } from "../Compte/Api";
const { Option } = Select;

const Caisse = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [Entitys, setEntitys] = useState([]);
  const [entityId, setEntityId] = useState(-1);
  const [nbBls, setNbBls] = useState(0);
  const [nbQuotes, setNbQuotes] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalHT, setTotalHT] = useState(0);

  useEffect(() => {
    AppStore.setFirst(2);
    AppStore.setSecond(13);
    toGetEntityList().then((res) => {
      setEntitys(res);
      form.setFieldsValue({
        entity: res[0].title,
      });
      setEntityId(res[0].id);
      getData(new Date(), res[0].id);
    });
  }, []);

  const getData = (date, entityId) => {
    setEntityId(entityId);
    setLoading(true);
    setData([]);
    setDate(date);
    toGetTotalTTC(date, entityId).then((res) => {
      setData(res);
      setTotal(res.reduce((a, b) => a + b.totalTTC, 0).toFixed(3));
      setTotalHT(res.reduce((a, b) => a + parseFloat(b.total), 0).toFixed(3));
      setNbBls(res.reduce((a, b) => a + b.totalBl, 0));
      setNbQuotes(res.reduce((a, b) => a + b.totalQuote, 0));
      setLoading(false);
    });
  };

  return (
    <>
      <Skeleton active loading={loading} />
      <Descriptions bordered size="default">
        <Descriptions.Item label="Total des bls TTC" className="item-label">
          <b>{total}</b>
        </Descriptions.Item>

        <Descriptions.Item label="Nombre de bls">
          <b>{nbBls}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Nombre de devis">
          <b> {nbQuotes}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Total des bls HT" className="item-label">
          <b>{totalHT}</b>
        </Descriptions.Item>
      </Descriptions>
      <Form form={form} style={{ marginTop: "2vh" }}>
        <Row className="auto--custom-header">
          <Col span="8">
            <Typography.Title level={3}>CAISSE</Typography.Title>
          </Col>
          <Col span="4">
            <Form.Item label="Date">
              <DatePicker
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  getData(date, entityId);
                }}
              />
            </Form.Item>
          </Col>
          <Col span="2" />
          <Form.Item
            rules={[{ required: true, message: "Champ obligatoire" }]}
            span={8}
            label="Entité"
            name="entity"
          >
            <Select
              showSearch
              value={Entitys.length > 0 && Entitys[0].title}
              style={{ width: "100%" }}
              onChange={(e) => getData(date, e)}
            >
              {Entitys.map((entity, i) => (
                <Option value={entity.id} key={i}>
                  {entity.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
      </Form>
      {data.length > 0 && (
        <Row>
          <Col span="24">
            <div style={{ height: 300 }}>
              <ResponsiveContainer>
                <BarChart
                  height={400}
                  data={data}
                  style={{ marginTop: "50px", marginBottom: "2vh" }}
                >
                  <XAxis dataKey="username" fontFamily="Proxima Nova"></XAxis>
                  <Tooltip cursor={{ fill: "transparent" }} />

                  <Bar
                    radius={[2, 2, 0, 0]}
                    dataKey="total"
                    barSize={200}
                    stackId="a"
                    fill="#846eb1"
                    fontFamily="Proxima Nova"
                  >
                    {data.map((entry, index) => (
                      <Cell></Cell>
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Caisse;
