import { Divider, Form, Input, Modal, Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { errorMsg, successMsg } from "../../../Utils/Utils";
import { toAddOrUpdateProvider } from "../APIs";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

const newResponsable = { name: "", tel: "" };
const ProviderModal = ({
  visible,
  providerForm,
  exitCreateModal,
  onProviderSaved,
  readonly,
}) => {
  const [form] = Form.useForm();
  const [responsables, setResponsables] = useState([newResponsable]);

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  useEffect(() => {
    if (null != providerForm.responsables) {
      setResponsables(providerForm.responsables);

      for (let index = 0; index < providerForm.responsables.length; index++) {
        const element = providerForm.responsables[index];
        providerForm["name" + index] = element.nameResp;
        providerForm["phone" + index] = element.phone;
      }
    }
    form.setFieldsValue(providerForm);
  }, [providerForm, readonly]);

  const closeCreateModal = () => {
    if (readonly) {
      exitCreateModal();
      return;
    }

    form
      .validateFields()
      .then((values) => {
        var results = [];
        var i = 0;
        var nameIndex = "name0";
        var phoneIndex = "phone0";
        while (values.hasOwnProperty(nameIndex)) {
          results.push({
            nameResp: values[nameIndex],
            phone: values[phoneIndex],
          });
          i++;
          nameIndex = "name" + i;
          phoneIndex = "phone" + i;
        }
        values.id = providerForm.id;
        values.responsables = results;

        toAddOrUpdateProvider(values).then((res) => {
          successMsg(
            providerForm.id != -1
              ? "Fournisseur mis à jours avec succées!"
              : "Fournisseur ajoutée avec succées!"
          );
          onProviderSaved(res);
        });
      })
      .catch((errorInfo) => {
        errorMsg(
          errorInfo["errorFields"][0]["label"] +
            " " +
            errorInfo["errorFields"][0]["errors"]
        );
      });
  };
  const toAdd = () => {
    setResponsables([...responsables, newResponsable]);
  };

  const toRemove = (i) => {
    var l__responsables = [...responsables];
    l__responsables.splice(i, 1);
    setResponsables(l__responsables);
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      title={
        -1 != -1 ? "Modifier un Fournisseur" : "Ajouter un nouveau Fournisseur"
      }
      visible={visible}
      onOk={closeCreateModal}
      onCancel={exitCreateModal}
    >
      <div style={{ scroll: "auto" }}>
        <Form name="basic" form={form} {...layout}>
          <Row gutter={24}>
            <Col md={12} sm={24}>
              <Form.Item
                label="Nom"
                name="s__nom"
                rules={[{ required: true, message: "Champ obligatoire" }]}
              >
                <Input disabled={readonly} />
              </Form.Item>

              <Form.Item
                label="Code"
                name="s__code"
                rules={[{ required: true, message: "Champ obligatoire" }]}
              >
                <Input disabled={readonly} />
              </Form.Item>

              <Form.Item
                label="Téléphone"
                name="s__phone"
                rules={[{ required: true, message: "Champ obligatoire" }]}
              >
                <Input disabled={readonly} />
              </Form.Item>

              <Form.Item label="Email" name="s__email">
                <Input disabled={readonly} />
              </Form.Item>

              <Form.Item
                label="Registre de commerce"
                name="s__register"
                rules={[{ required: true, message: "Champ obligatoire" }]}
              >
                <Input disabled={readonly} />
              </Form.Item>

              <Form.Item label="Fax" name="s__fax">
                <Input disabled={readonly} />
              </Form.Item>

              <Form.Item
                label="Matricule fiscale"
                name="s__matricule"
                rules={[{ required: true, message: "Champ obligatoire" }]}
              >
                <Input disabled={readonly} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              {responsables.map((responsable, i) => (
                <>
                  <Divider>
                    {"Responsable " + (i + 1)}
                    {!readonly && responsables.length > 1 && (
                      <Button
                        icon={<DeleteOutlined />}
                        style={{ marginLeft: "5%" }}
                        onClick={(e) => toRemove(i)}
                      ></Button>
                    )}
                  </Divider>

                  <Form.Item
                    label="Nom Responsable"
                    name={"name" + i}
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                  >
                    <Input disabled={readonly} />
                  </Form.Item>

                  <Form.Item
                    label="Téléphone Responsable"
                    name={"phone" + i}
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                  >
                    <Input disabled={readonly} />
                  </Form.Item>
                </>
              ))}
              {!readonly && (
                <Button
                  icon={<PlusOutlined />}
                  style={{ marginLeft: "40%" }}
                  onClick={(e) => toAdd()}
                  type="primary"
                >
                  Ajouter Responsable
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
export default ProviderModal;
