import {
  Button,
  Typography,
  Switch,
  Col,
  Row,
  Input,
  Skeleton,
  Tooltip,
  Popconfirm,
  Modal,
  Select,
  Form,
  Descriptions,
  DatePicker,
} from "antd";

import { SaveOutlined, NotificationTwoTone } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  toDeleteBL,
  toGetBonsLivraision,
  toPrintBL,
  toUpdateBlData,
  toPrintBLs,
  toSendNotification,
  toGetClientsListIds,
  toGetTotal,
} from "../APIs";

import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BonLivraisonModal from "./BonLivraisonModal";
import { toConvertDate, customFilter, successMsg } from "../../../Utils/Utils";
import cookie from "react-cookies";

import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";
import FactureModal from "../Factures/FactureModal";
import Search from "../../../Utils/Search";
import { toGetUsers } from "../../Compte/Api";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import BlToFacture from "./BlToFacture";
import ClientDetail from "../Clients/ClientDetail";
import Client from "../../../Models/Client";

const { Option } = Select;

const fields = [
  { name: "Référence", field: "s__ref", type: "varchar" },
  { name: "Total", field: "total", type: "number" },
  { name: "Client", field: "clientName", type: "varchar" },
  {
    name: "Client Affiché",
    field: "displayableName",
    type: "varchar",
  },

  { name: "Matricule Fiscale", field: "mf", type: "varchar" },
  { name: "Remise", field: "discount", type: "number" },
  { name: "Nombre d'articles", field: "nb", type: "number" },
  { name: "Payé", field: "paied", type: "boolean" },
  { name: "Date de création", field: "createdDate", type: "date" },
  { name: "Région", field: "region", type: "varchar" },
];

const ListeBonLivraison = ({ AppStore, ...props }) => {
  const [bonsLivraison, setBonsLivraison] = useState([]);
  const [bonsLivraisonALL, setBonsLivraisonAll] = useState([]);
  const [selectedMethode, setSelectedMethode] = useState(2);
  const [selectedClient, setSelectedClient] = useState("0");
  const [selectedDate, setSelectedDate] = useState(0);
  const [blToInspect, setBlToInspect] = useState(null);
  const [factureToInspect, setFactureToInspect] = useState(null);
  const [entete, setEntete] = useState(true);
  const [displayDate, setDisplayDate] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [blsToFacture, setBlsToFacture] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [totalTVA, setTotalTVA] = useState(0);
  const [total, setTotal] = useState(0);
  const [clients, setClients] = useState([]);
  const [totalUnpaied, setTotalUnpaied] = useState(0);
  const [totalPaied, setTotalPaied] = useState(0);
  const [loading, setLoading] = useState(true);
  const [notificationModal, setNotificationModal] = useState(false);
  const [nb, setNb] = useState(1);

  const [createFactureModalVisible, setCreateFactureModalVisible] = useState(
    false
  );
  const [searchValue, setSearchValue] = useState("");
  const [notifText, setNotifText] = useState(
    "Merci de vérifier les bls suivants"
  );
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(0);
  const [selectedDateValue, setSelectedDateValue] = useState("0");
  const [show, setShow] = useState(true);
  const [filtredData, setFiltredData] = useState([]);
  const [detailState, setDetailState] = useState({
    client: new Client(),
    visible: false,
  });

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const fetchBLS = async (page, options) => {
    setLoading(true);
    setShow(true);
    setTotalRows(0);
    setTotalUnpaied(0);
    setTotalPaied(0);
    setTotal(0);
    toGetBonsLivraision(page, perPage, options).then((data) => {
      setBonsLivraison(data.res);
      setBonsLivraisonAll(data.res);
      setLoading(false);

      setShow(false);
    });
    toGetTotal(options).then((data) => {
      setTotalRows(data.nb);
      setTotalUnpaied(data.totalUnpaied);
      setTotalPaied(data.totalPaied);
      setTotal(data.total);
    });
  };

  const handlePageChange = (page) => {
    fetchBLS(page - 1, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setShow(true);

    toGetBonsLivraision(page - 1, newPerPage, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    }).then((data) => {
      setBonsLivraison(data.res);
      setBonsLivraisonAll(data.res);
      setLoading(false);
      setShow(false);
    });

    setPerPage(newPerPage);
    setLoading(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };
  const columns = [
    {
      name: "Client",
      selector: "clientName",
      sortable: true,
      grow: 1.5,
    },

    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 0.75,
    },
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.25,

      format: (row) => `${toConvertDate(row.createdDate)}`,
    },
    {
      name: "Référence",
      selector: "ref",
      sortable: true,
      format: (bl) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setBlToInspect(bl.id)}
        >
          {bl.ref}
        </span>
      ),
    },
    {
      name: "Client affiché",
      selector: "displayableName",
      sortable: true,
      grow: 1.5,
      format: (bl) => (
        <Row gutter={24}>
          <Col span={24}>
            <Input
              onBlur={(e) => toUpdateClientName(bl, e.target.value)}
              defaultValue={bl.displayableName}
            />
          </Col>
        </Row>
      ),
    },
    {
      name: "MF affiché",
      selector: "displayableMF",
      sortable: true,
      grow: 1.5,

      format: (bl) => (
        <Row gutter={24}>
          <Col span={18}>
            <Input
              onBlur={(e) => toUpdateMf(bl, e.target.value)}
              defaultValue={bl.displayableMF}
            />
          </Col>
          <Col span={4} style={{ float: "left" }}>
            <Button
              type="link"
              onClick={() => toUpdateClientAndMF(bl)}
              icon={<SaveOutlined />}
            ></Button>
          </Col>
        </Row>
      ),
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
      grow: 0.75,

      format: (row) => `${row.total.toFixed(3) + " DT"}`,
    },
    {
      name: "Reste",
      selector: "reste",
      sortable: true,
      grow: 0.75,

      format: (row) => `${row.reste.toFixed(3) + " DT"}`,
    },
    {
      name: "Payé",
      selector: "paied",
      sortable: true,
      grow: 0.75,

      format: (row) =>
        row.paied ? (
          <span className={"auto--color-success"}>Payé</span>
        ) : (
          <span className={"auto--color-danger"}>Non payé</span>
        ),
    },
    {
      name: "Note",
      selector: "note",
      grow: 0.75,
    },
    {
      name: "Facture",
      selector: "factureRef",
      sortable: true,
      grow: 0.75,

      format: (bl) =>
        bl.factureId && (
          <Typography.Link onClick={() => setFactureToInspect(bl.factureId)}>
            {bl.factureRef}
          </Typography.Link>
        ),
    },
  ];

  useEffect(() => {
    AppStore.setFirst(1);
    AppStore.setSecond(8);

    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
    toGetClientsListIds().then((_clients) => {
      setClients(_clients);
    });
    AppStore.setFirst(1);
    AppStore.setSecond(8);

    toGetUsers().then((users) => {
      setUsers(users);
    });
  }, []);

  const actualise = (nb) => {
    setShow(true);
    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const toUpdateData = (values) => {
    setBonsLivraison(values);
    setFilterModalVisible(false);
  };

  const onSearch = (e, data) => {
    // var bons = null != data ? data : filtredData;
    // setSearchValue(e.target.value);
    // const updatedList = customFilter(e.target.value, bons, [
    //   "clientName",
    //   "total",
    //   "ref",
    //   "note",
    //   "date",
    //   "displayableName",
    //   "displayableMF",
    // ]);

    // setBonsLivraison(updatedList);
    // if (updatedList.length == 1) {
    //   toggleFactureBL(updatedList[0]);
    // } else {
    //   setBlsToFacture([]);
    // }
    setSearchValue(e);

    setLoading(true);

    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: e,
    });
  };

  const printBl = (e) => {
    e.preventDefault();
    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }

    if (blsToFacture.length == 1) {
      toPrintBL(blsToFacture[0].id, titre1, entete).then(() => {
        var index = bonsLivraison.findIndex((a) => a.id == blsToFacture[0].id);
        let newbls = [...bonsLivraison];
        newbls[index].locked = 1;
        setBonsLivraison(newbls);
      });
    } else {
      var ids = [];
      blsToFacture.forEach((element) => {
        ids.push(element.id);
      });
      toPrintBLs(ids);
    }
  };

  const toggleFactureBL = (bl) => {
    const blExists = blsToFacture.find((blf) => blf.id == bl.id);

    if (blExists) {
      setBlsToFacture(blsToFacture.filter((blf) => blf.id != bl.id));
      setTotalRows(total - bl.total);
      setTotalTVA(totalTVA - bl.tva);
    } else {
      setBlsToFacture(blsToFacture.concat(bl));
      setTotalRows(total + bl.total);
      setTotalTVA(totalTVA + bl.tva);
    }
  };

  const creerFacture = () => {
    //setLoading(true);
    setCreateFactureModalVisible(true);
    // toCreateFacture(blsToFacture).then(() => {
    //   setBlsToFacture([]);
    //   toGetBonsLivraision().then((data) => {
    //     history.push("/vente/factures");
    //     cookie.save("selectedMenu", 9, { path: "/" });
    //   });
    // });
  };

  const toUpdateMf = (bl, value) => {
    const newBls = [...bonsLivraison];
    const index = newBls.findIndex((a) => a.id === bl.id);
    newBls[index].displayableMF = value;
    setBonsLivraison(newBls);
  };

  const toUpdateClientName = (bl, value) => {
    const newBls = [...bonsLivraison];
    const index = newBls.findIndex((a) => a.id === bl.id);

    newBls[index].displayableName = value;
    setBonsLivraison(newBls);
  };

  const toUpdateClientAndMF = (quote) => {
    var data = {
      clientName: quote.displayableName,
      mf: quote.displayableMF,
      id: quote.id,
    };
    toUpdateBlData(data).then(() => {
      successMsg("informations mis à jours avec succée! ");
    });
  };

  const inspectFacture = (idFacture) => {
    setFactureToInspect(idFacture);
    // toGetFacture(idFacture).then(
    //   (facture) => facture && setFactureToInspect(facture)
    // );
  };

  const updateBl = (bl) => {
    const win = window.open(
      "/vente/bl/modifier/" + bl.id,

      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  const toDisplayDelete = () => {
    var display = true;
    blsToFacture.forEach((element) => {
      if (element.paied || element.factureId != null) {
        display = false;
        return;
      }
    });

    return display;
  };
  const toDisplayFacture = () => {
    var display = true;

    for (let index = 1; index < blsToFacture.length; index++) {
      const element = blsToFacture[index];
      if (element.clientId != blsToFacture[index - 1].clientId) {
        display = false;
      }
    }

    blsToFacture.forEach((element) => {
      if (element.factureId != null) {
        display = false;
        return;
      }
    });
    return display;
  };

  const toDisplayPrint = () => {
    return true;
    var display = true;
    for (let index = 1; index < blsToFacture.length; index++) {
      const element = blsToFacture[index];
      if (element.clientId != blsToFacture[index - 1].clientId) {
        display = false;
      }
    }
    return display;
  };

  const onSelectRow = (e) => {
    setBlsToFacture(e.selectedRows);
  };

  const onAddBl = (bl) => {
    setBlsToFacture(blsToFacture.concat(bl));
  };

  const onRemoveBl = (bl) => {
    setBlsToFacture(blsToFacture.filter((blf) => blf.id != bl.id));
    if (blsToFacture.filter((blf) => blf.id != bl.id).length == 0) {
      setCreateFactureModalVisible(false);
    }
  };

  const onChange = () => {
    setEntete(!entete);
  };
  const deleteBL = () => {
    var ids = [];
    blsToFacture.forEach((element) => {
      ids.push(element.id);
    });
    setLoading(true);
    toDeleteBL(ids).then(() => {
      fetchBLS(0, {
        paied: selectedMethode,
        client: selectedClient,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
      });
      setBlsToFacture([]);
    });
  };

  const onChangeCredit = (option) => {
    setSelectedMethode(option);
    fetchBLS(0, {
      paied: option,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeClient = (option) => {
    const id = clients.filter((el) => el.name == option)[0].id;
    setSelectedClient(id);
    fetchBLS(0, {
      paied: selectedMethode,
      client: id,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeUsers = (option) => {
    var id = "0";
    if (option != "0") {
      id = users.filter((el) => el.username == option)[0].id;
    }
    setSelectedUser(id);
    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: id,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeDate = (option) => {
    if (option == "5") {
      setSelectedDate(option);
      setDisplayDate(true);
      setDisplayDateRange(false);
      return;
    }
    if (option == "6") {
      setSelectedDate(option);
      setDisplayDateRange(true);
      setDisplayDate(false);
      return;
    }
    setDisplayDate(false);

    setSelectedDate(option);

    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: option,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const sendNotification = () => {
    setNotificationModal(false);
    var bls = [];
    blsToFacture.forEach((element) => {
      bls.push(element.id);
    });
    toSendNotification(notifText, bls).then(() => {
      successMsg("Notification envoyée avec succés");
    });
  };

  return (
    <div id="vente">
      <Loading show={show} color="red" showSpinner={true} />
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={5}>
              LISTE DES BON DE LIVRAISONS
            </Typography.Title>
          </Col>
          <Col span="3" style={{ textAlign: "right" }} justify="end">
            <Form.Item label="Réf,note,client/mf affiché">
              <Input.Search onSearch={onSearch} enterButton />
            </Form.Item>
          </Col>
          <Col span="2">
            <Form.Item label="Crédit">
              <Select
                onSelect={onChangeCredit}
                style={{ width: "80%" }}
                defaultValue="2"
              >
                <Option value="2">Tout</Option>
                <Option value="0">Non payé</Option>
                <Option value="1">Payé</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Date">
              <Select
                showSearch
                onSelect={onChangeDate}
                style={{ width: "100%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                {/* <Option value="4">Cette année</Option>*/}
                <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && (
              <DatePicker
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBLS(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
            {displayDateRange && (
              <DatePicker.RangePicker
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBLS(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
          </Col>

          <Col span="4">
            <Form.Item label="Client">
              <Select
                showSearch
                onSelect={onChangeClient}
                style={{ width: "80%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                {clients.map((c) => (
                  <Option value={c.name}>{c.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Utilisateurs">
              <Select
                showSearch
                onSelect={onChangeUsers}
                style={{ width: "80%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                {users.map((u) => (
                  <Option value={u.username}>{u.username}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span="2" style={{ textAlign: "right" }}>
            <Button
              type="link"
              onClick={(e) => setFilterModalVisible(true)}
              icon={
                <img
                  src="/images/filterIconVente.svg"
                  style={{ width: "3vh", height: "3vh" }}
                />
              }
              style={{ marginRight: "5px" }}
            ></Button>
            <Link key="1" to="/vente/bl/creer">
              <Button
                type="link"
                size="large"
                icon={<img src="/images/plusVente.svg" />}
              ></Button>
            </Link>
            <Tooltip title="Actualiser la liste des bls">
              <Button
                onClick={() => actualise(nb)}
                type="link"
                size="large"
                icon={<img src="/images/refresh.svg" />}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </Form>{" "}
      <Row gutter="24" style={{ marginTop: "1vh" }}>
        <Col span="23">
          <Skeleton active loading={loading} />

          <DataTable
            selectA
            customStyles={customStyles}
            selectableRows
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            onSelectedRowsChange={(e) => onSelectRow(e)}
            persistTableHead
            noDataComponent={<span>Pas de bon de livraisons trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)",
            }}
            columns={columns}
            data={bonsLivraison}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            selectableRows
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />

          {cookie.load("role") == "Super Admin" && total > 0 && (
            <Descriptions
              bordered
              size="small"
              style={{ backgroundColor: "white", marginTop: "3px" }}
            >
              <Descriptions.Item label="Total TTC" className="item-label">
                {total.toFixed(3) + " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total payé" className="item-label">
                {totalPaied.toFixed(3) + " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total Reste" className="item-label">
                {totalUnpaied.toFixed(3) + " DT"}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Col>

        <Col span="1" className="auto--background-grey">
          {blsToFacture.length > 0 && (
            <div style={{ marginTop: "5vh" }}>
              {toDisplayPrint() && (
                <>
                  <Tooltip
                    title="Pour imprimer avec/sans entête"
                    color="#58c5cc"
                    placement="leftTop"
                  >
                    <Switch checked={entete} onChange={() => onChange()} />
                  </Tooltip>
                  <Tooltip title="Imprimer" color="#58c5cc" placement="leftTop">
                    <Button
                      type="link"
                      onClick={(e) => printBl(e)}
                      onContextMenu={(e) => printBl(e)}
                      style={{ marginTop: "2vh" }}
                      icon={
                        <img
                          src="/images/print.svg"
                          className="auto--icon-list"
                        ></img>
                      }
                    ></Button>
                  </Tooltip>
                </>
              )}
              {blsToFacture.length === 1 && (
                <Tooltip
                  placement="leftTop"
                  title="Détails du bon de livraison"
                  color="#58c5cc"
                >
                  <Button
                    disabled={blsToFacture.length > 1}
                    type="link"
                    style={{ marginTop: "2vh" }}
                    onClick={() => setBlToInspect(blsToFacture[0].id)}
                    icon={
                      <img
                        src="/images/info.svg"
                        className="auto--icon-list"
                      ></img>
                    }
                  ></Button>
                </Tooltip>
              )}
              {blsToFacture.length === 1 &&
                !blsToFacture[0].paied &&
                blsToFacture[0].factureId == null && (
                  <Tooltip
                    title="Modifier le bon de livraison"
                    color="#58c5cc"
                    placement="leftTop"
                  >
                    <Button
                      type="link"
                      disabled={blsToFacture.length > 1}
                      style={{ marginTop: "2vh" }}
                      onClick={() => updateBl(blsToFacture[0])}
                      icon={
                        <img
                          src="/images/edit.svg"
                          className="auto--icon-list"
                        ></img>
                      }
                    ></Button>
                  </Tooltip>
                )}

              {toDisplayDelete() && (
                <Tooltip
                  title="Supprimer le(s) bon(s) de livraison"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Popconfirm
                    title={`Voulez vous vraiment supprimer ce bon de livraison?`}
                    onConfirm={() => deleteBL()}
                    okText="Oui"
                    cancelText="Non"
                    placement="left"
                  >
                    <Button
                      type="link"
                      style={{ marginTop: "2vh" }}
                      icon={
                        <img
                          src="/images/trash.svg"
                          className="auto--icon-list"
                        ></img>
                      }
                    ></Button>
                  </Popconfirm>
                </Tooltip>
              )}
              {toDisplayFacture() && (
                <Tooltip
                  title="Pour facturer le(s) bon(s) de livraison"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={() => creerFacture()}
                    style={{ marginTop: "3vh" }}
                    icon={
                      <img
                        src="/images/factureBlack.svg"
                        className="auto--icon-list"
                      ></img>
                    }
                  ></Button>
                </Tooltip>
              )}

              {cookie.load("role") == "Super Admin" && (
                <Tooltip
                  title="Pour envoyer une notification"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={() => setNotificationModal(true)}
                    style={{ marginTop: "3vh" }}
                    size="large"
                    icon={<NotificationTwoTone />}
                  ></Button>
                </Tooltip>
              )}
            </div>
          )}
        </Col>
      </Row>
      {createFactureModalVisible && (
        <BlToFacture
          AppStore={AppStore}
          onAddBl={(bl) => onAddBl(bl)}
          onRemoveBl={(bl) => onRemoveBl(bl)}
          setCreateFactureModalVisible={(v) => setCreateFactureModalVisible(v)}
          visible={createFactureModalVisible}
          blsToFacture={blsToFacture}
        />
      )}
      <BonLivraisonModal
        entete={entete}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
      <FactureModal
        id={null != factureToInspect && factureToInspect}
        onClose={() => setFactureToInspect(null)}
      />
      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search
          toUpdateData={toUpdateData}
          fields={fields}
          list={bonsLivraisonALL}
        />
      </Modal>
      <Modal
        title="Envoyer une notification"
        visible={notificationModal}
        footer={[]}
        onCancel={(e) => setNotificationModal(false)}
      >
        <Row>
          <Col span="8">Texte à envoyer</Col>
          <Col span="16">
            <Input
              value={notifText}
              onChange={(e) => setNotifText(e.target.value)}
            />
          </Col>
        </Row>

        <div style={{ marginTop: "2vh" }}>
          Le(s) bons de livraisons séléctionné(s):
        </div>
        <ul>
          {blsToFacture.map((e) => (
            <li>{e.ref}</li>
          ))}
        </ul>
        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={() => sendNotification()}>
            Envoyer la notification
          </Button>
        </div>
      </Modal>
      <ClientDetail
        client={detailState.client}
        visible={detailState.visible}
        dismiss={() => setDetailState({ client: new Client(), visible: false })}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBlToUpdate: (bl) => dispatch(setItemsState(bl)),
  };
};

export default connect(null, mapDispatchToProps)(ListeBonLivraison);
