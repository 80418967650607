import { Menu, Row, Col, Button } from "antd";

import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { logout } from "../Actions/AuthActions";
import { connect } from "react-redux";
import cookie from "react-cookies";
import moment from "moment";
import localization from "moment/locale/fr";
import Modal from "antd/lib/modal/Modal";
import Tecdoc from "../Utils/Tecdoc";

moment.updateLocale("fr", localization);

const { SubMenu } = Menu;

const Navigation = ({ AppStore, ...props }) => {
  const [current, setCurrent] = useState("Achat");
  const [selectedMenu, setSelectedMenu] = useState(
    null != localStorage.getItem("index") ? localStorage.getItem("index") : 0
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [time, setTime] = useState("");
  useEffect(() => {
    setInterval(() => {
      setTime(moment().format("LL HH:mm:ss"));
    }, 1000);

    window.onkeydown = (evt) => {
      switch (evt.keyCode) {
        //ESC
        case 27:
          evt.preventDefault();
          console.log("esc");
          break;
        //F1
        case 112:
          setIsModalVisible(true);
          evt.preventDefault();
          console.log("f1");
          break;
        default:
          return;
      }
    };
  }, [AppStore.first]);
  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const setMode = (i) => {
    setSelectedMenu(i);
    AppStore.setFirst(i);
  };

  const setBoth = (i, j) => {
    setSelectedMenu(i);

    AppStore.setFirst(i);
    AppStore.SetSecond(j);
    setIsModalVisible(false);
  };

  return (
    <div>
      <Row className="auto--background-grey" style={{ margin: 0 }}>
        <Col span={3}>
          <span style={{ marginLeft: "1vh", color: "#432c79" }}>
            <b>{cookie.load("nom")}</b>
          </span>
        </Col>
        <Col span={16}>
          <Menu
            onClick={handleClick}
            style={{
              textAlign: "right",
              marginRight: "25vh",
              backgroundColor: "#f0f0f0",
            }}
            selectedKeys={[current]}
            mode="horizontal"
            theme="light"
          >
            <Menu.Item
              danger={true}
              className={AppStore.first == 0 && "auto--menu-achat-background"}
              key="Achat"
              onClick={(e) => {
                setMode(0);
              }}
            >
              <Link to="/achat"> Achat</Link>
            </Menu.Item>
            <Menu.Item
              key="Vente"
              className={AppStore.first == 1 && "auto--menu-vente-background"}
              onClick={(e) => {
                setMode(1);
              }}
            >
              <Link to="/vente">Vente</Link>
            </Menu.Item>
            <Menu.Item
              key="Objectfs"
              onClick={(e) => {
                setMode(2);
              }}
            >
              <Link to="/">Objectifs & stats</Link>
            </Menu.Item>
            <SubMenu key="compte" title="Compte">
              <Menu.Item
                key="profile"
                onClick={(e) => {
                  setMode(2);
                }}
              >
                <Link to="/compte/profile">Mon profile</Link>
              </Menu.Item>
              {cookie.load("role") == "Super Admin" && (
                <SubMenu key="gestion" title="Gestion">
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="entities"
                  >
                    <Link to="/compte/entity">entités</Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="tva"
                  >
                    <Link to="/Gestions/tva">TVA</Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="emplacement"
                  >
                    <Link to="/Gestions/emplacement">emplacement</Link>
                  </Menu.Item>

                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="category"
                  >
                    <Link to="/Gestions/category">catégories</Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="unite"
                  >
                    <Link to="/Gestions/unite">unité</Link>
                  </Menu.Item>

                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="marque"
                  >
                    <Link to="/Gestions/marque">marque</Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key="users"
                >
                  <Link to="/compte/users">Utilisateurs</Link>
                </Menu.Item>
              )}
              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key="parameters"
                >
                  <Link to="/Gestions/preference">Préférences</Link>
                </Menu.Item>
              )}
              <Menu.Item onClick={props.logout} key="Logout">
                Déconnexion
              </Menu.Item>
            </SubMenu>

            <Button
              type="link"
              onClick={() => setIsModalVisible(true)}
              icon={
                <img
                  src="/images/detailArticle.svg"
                  style={{ height: "90%" }}
                />
              }
            ></Button>
          </Menu>
        </Col>
        <Col span={3} style={{ textAlign: "right" }}>
          <span>
            <b>{time}</b>
          </span>
        </Col>
      </Row>

      <Modal
        title="Liste des articles"
        visible={isModalVisible}
        className="smtv-modal"
        maskClosable={false}
        onCancel={(e) => setIsModalVisible(false)}
        footer={[]}
        width="180vh"
      >
        <Tecdoc
          displayTitle={true}
          addItem={null}
          reference={""}
          achat={false}
        />{" "}
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Navigation);
