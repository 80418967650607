import { useState, useEffect } from "react";
import { Input, Alert, Modal } from "antd";
import { toGetCode } from "../Compte/Api";

const ModalCode = ({
  onOk,
  isAdminModalVisible,
  setAdminModalVisible,
  text,
}) => {
  const [adminCode, setAdminCode] = useState("");
  const [code, setCode] = useState("");
  const [wrongAdminCode, setWrongAdminCode] = useState(false);
  useEffect(() => {
    toGetCode().then((res) => {
      setCode(res.code);
    });
  }, [isAdminModalVisible, text]);

  const save = () => {
    if (adminCode == code) {
      onOk();
    } else {
      setWrongAdminCode(true);
    }
  };

  return (
    <Modal
      title="Operation non autorisée"
      visible={isAdminModalVisible}
      onOk={save}
      onCancel={() => setAdminModalVisible(false)}
    >
      <p>{text}</p>
      {wrongAdminCode && (
        <Alert
          message="Code admin incorrect"
          type="error"
          style={{ marginBottom: 10 }}
        />
      )}
      <Input
        autoComplete="new-password"
        placeholder="Code admin"
        type="password"
        onChange={(e) => setAdminCode(e.target.value)}
      />
    </Modal>
  );
};
export default ModalCode;
