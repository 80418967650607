import { Descriptions, Tabs, Modal, Button } from "antd";
import { useEffect, useState } from "react";
import { toConvertDate } from "../../Utils/Utils";
import { toGetArticleHistory } from "./Api";
import DataTable from "react-data-table-component";
import QuoteModal from "../Vente/Quotes/QuoteModal";
import BonLivraisonModal from "../Vente/BL/BonLivraisonModal";

import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import BonReceptionModal from "../Achat/BonDeReception/BonReceptionModal";

const { TabPane } = Tabs;

const ArticleHistory = ({ article, visible, setArticleHistory }) => {
  const [list, setList] = useState([]);
  const [quoteToInspect, setQuoteToInspect] = useState(null);
  const [blToInspect, setBlToInspect] = useState(null);
  const [show, setShow] = useState(true);
  const [brToInspect, setBrToInspect] = useState(null);

  useEffect(() => {
    toGetArticleHistory(article.id).then((res) => {
      setList(res);
      setShow(false);
    });
  }, [article]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.type == 1,
      style: {
        backgroundColor: "rgba(88, 197, 204,0.2)",
      },
    },
    {
      when: (row) => row.type == 2,
      style: {
        backgroundColor: "#FAFAFA",
      },
    },
    {
      when: (row) => row.type == 3,
      style: {
        backgroundColor: "rgba(78, 180, 230, 0.2)",
      },
    },
  ];
  const cancelCreateModal = () => {
    setArticleHistory(null);
  };

  const columns = [
    {
      name: "Type",
      selector: "type",
      sortable: true,
      grow: 0.5,

      format: (row) => (
        <span style={{ fontWeight: "bold" }}>
          {" "}
          {row.type == 1 ? "BL" : row.type == 2 ? "Devis" : "Bon Receptions"}
        </span>
      ),
    },
    {
      name: "Client",
      selector: "clientName",
      sortable: true,
    },
    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
    },
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.5,
      format: (row) => `${toConvertDate(row.createdDate)}`,
    },
    {
      name: "Référence",
      selector: "ref",
      sortable: true,
      format: (bl) =>
        bl.type == 2 ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setQuoteToInspect(bl)}
          >
            {bl.ref}
          </span>
        ) : bl.type == 1 ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setBlToInspect(bl.id)}
          >
            {bl.ref}
          </span>
        ) : (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setBrToInspect(bl)}
          >
            {bl.ref}
          </span>
        ),
    },
    {
      name: "Client affiché",
      selector: "displayableName",
      sortable: true,
    },
    {
      name: "MF affiché",
      selector: "displayableMF",
      sortable: true,
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
      format: (row) => `${row.total.toFixed(3) + " DT"}`,
    },

    {
      name: "Note",
      selector: "note",
    },
  ];

  return (
    <>
      <Modal
        className="smtv-modal modal-large"
        title={article && article.titre2}
        visible={visible}
        maskClosable={false}
        onCancel={(e) => cancelCreateModal()}
        footer={[
          <Button key="back" onClick={(e) => cancelCreateModal()}>
            Ok
          </Button>,
        ]}
        onOk={cancelCreateModal}
      >
        <Descriptions bordered size="default">
          <Descriptions.Item label="Titre" className="item-label">
            {article.titre}
          </Descriptions.Item>
          <Descriptions.Item label="Titre 2">
            {article.titre2}
          </Descriptions.Item>
          <Descriptions.Item label="Reférence">
            {article.reference}
          </Descriptions.Item>
        </Descriptions>

        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Tout " key="1">
            <DataTable
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              columns={columns}
              data={list}
              pagination
              noHeader
              fixedHeader
              persistTableHead
              noDataComponent={<span>Pas de resultats trouvés</span>}
              contextMessage={{
                singular: "élément",
                plural: "éléments",
                message: "séléctionné(s)",
              }}
            />
          </TabPane>
          <TabPane tab="Devis" key="2">
            <DataTable
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              columns={columns}
              data={list.filter((a) => a.type == 2)}
              pagination
              noHeader
              fixedHeader
              persistTableHead
              noDataComponent={<span>Pas de resultats trouvés</span>}
              contextMessage={{
                singular: "élément",
                plural: "éléments",
                message: "séléctionné(s)",
              }}
            />
          </TabPane>
          <TabPane tab="Bon livraisons " key="3">
            <DataTable
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              columns={columns}
              data={list.filter((a) => a.type == 1)}
              pagination
              noHeader
              fixedHeader
              persistTableHead
              noDataComponent={<span>Pas de resultats trouvés</span>}
              contextMessage={{
                singular: "élément",
                plural: "éléments",
                message: "séléctionné(s)",
              }}
            />
          </TabPane>
          <TabPane tab="Bon réceptions " key="4">
            <DataTable
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              columns={columns}
              data={list.filter((a) => a.type == 3)}
              pagination
              noHeader
              fixedHeader
              persistTableHead
              noDataComponent={<span>Pas de resultats trouvés</span>}
              contextMessage={{
                singular: "élément",
                plural: "éléments",
                message: "séléctionné(s)",
              }}
            />
          </TabPane>
        </Tabs>
      </Modal>

      <QuoteModal
        quote={quoteToInspect}
        entete={true}
        onClose={() => setQuoteToInspect(null)}
      />
      <BonReceptionModal
        bonreception={brToInspect}
        onClose={() => setBrToInspect(null)}
      />
      <BonLivraisonModal
        entete={true}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
    </>
  );
};
export default ArticleHistory;
