import { Drawer, Button } from "antd";
import { useEffect } from "react";

const ProviderDetail = ({ visible }) => {
  useEffect(() => {}, []);

  return (
    <Drawer
      title="Detail Fournisseur"
      width={"80%"}
      visible={visible}
      //onClose={exitCreateModal}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button //onClick={exitCreateModal}
            style={{ marginRight: 8 }}
          >
            Annuler
          </Button>
        </div>
      }
    >
      <div style={{ scroll: "auto" }}></div>
    </Drawer>
  );
};
export default ProviderDetail;
