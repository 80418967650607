import {
  Button,
  Descriptions,
  Typography,
  Skeleton,
  Row,
  Col,
  Modal,
  Input,
  Form,
  Popconfirm,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/fr";
import DataTable from "react-data-table-component";

import {
  toDeleteReglementClient,
  toFilterReglement,
  toGetClientsListIds,
  toGetReglementsClient,
} from "../APIs";
import { customFilter, toConvertDate } from "../../../Utils/Utils";
import Search from "../../../Utils/Search";
import { BiTrash } from "react-icons/bi";
import ls from "localstorage-ttl";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
const { Option } = Select;

moment.updateLocale("fr", localization);

const ReglementsListClient = ({ AppStore, ...props }) => {
  const [reglements, setReglements] = useState([]);
  const [reglementsAll, setReglementsAll] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [clients, setClients] = useState([]);
  const [id, setId] = useState(-1);

  const fields = [
    { name: "Référence", field: "s__ref", type: "varchar" },
    { name: "Montant", field: "montant", type: "number" },
    { name: "Date ", field: "date", type: "date" },
  ];

  const onSearch = (e) => {
    setSearchValue(e);
    toFilterReglement(e, 0, perPage).then((res) => {
      setReglements(res.data);
      setTotalRows(res.total);
      setLoading(false);
      setShow(false);
    });
  };
  useEffect(() => {
    AppStore.setFirst(1);
    AppStore.setSecond(10);
    toGetClientsListIds().then((_clients) => {
      setClients(_clients);
    });
    toGetData(0, perPage, -1);
  }, []);

  const toUpdateData = (values) => {
    setReglements(values);
    setFilterModalVisible(false);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    toGetData(page - 1, newPerPage, id);

    setPerPage(newPerPage);
    setLoading(false);
  };

  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";
        break;
      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";
      case 5:
        return "Retenu à la source";
      case 5:
        return "Contre remboursement";

      default:
        break;
    }
  };

  const toGetData = (page, newPerPage, id) => {
    setLoading(true);
    setShow(true);

    if (searchValue != "") {
      toFilterReglement(searchValue, page, perPage, id).then((res) => {
        setReglements(res.data);
        setTotalRows(res.total);
        setLoading(false);
        setShow(false);
      });
      return;
    }
    if (ls.get("reglements")) {
      setReglements(ls.get("reglements"));
      setReglementsAll(ls.get("reglements"));
      setLoading(false);
    }
    toGetReglementsClient(page, newPerPage, id).then((data) => {
      data.res.forEach((element) => {
        element.num = element.data.num;
        element.banque = element.data.banque;
      });
      setReglements(data.res);
      setReglementsAll(data.res);
      ls.set("reglements", data.res);
      setTotalRows(data.total);
      setShow(false);
      setLoading(false);
    });
  };

  const deleteReglement = (i__id) => {
    setLoading(true);
    toDeleteReglementClient(i__id).then(() => {
      toGetData(0, perPage, id);
    });
  };

  const handlePageChange = (page) => {
    toGetData(page - 1, perPage, id);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  const columns = [
    {
      name: "Référence",
      selector: "s__ref",
      sortable: true,
      grow: 1,
    },
    {
      name: "Client",
      selector: "clientName",
      sortable: true,
      grow: 1,
    },

    {
      name: "Facture/BL",
      selector: "ref",
      sortable: true,
      format: (bl) => <b>{bl.ref}</b>,
      grow: 1,
    },
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.5,

      format: (row) => `${toConvertDate(row.createdDate)}`,
    },
    {
      name: "Type",
      selector: "ref",
      format: (bl) => getReglementType(bl),
    },

    {
      name: "Détails",
      selector: "s__ref",
      grow: 6,
      format: (reglement) => (
        <Descriptions
          column={5}
          bordered
          size="small"
          layout={
            reglement.type == 1 || reglement.type == 2
              ? "vertical"
              : "horizontal"
          }
        >
          {reglement.type == 1 || reglement.type == 2 ? (
            <>
              <Descriptions.Item label="Num" className="item-label">
                {reglement.data.num}
              </Descriptions.Item>
              <Descriptions.Item label="Nom" className="item-label">
                {reglement.data.nom}
              </Descriptions.Item>
              <Descriptions.Item label="Date" className="item-label">
                {toConvertDate(reglement.data.date)}
              </Descriptions.Item>
              <Descriptions.Item label="Banque" className="item-label">
                {reglement.data.banque}
              </Descriptions.Item>
              <Descriptions.Item label="Montant" className="item-label">
                {reglement.data.montant}
              </Descriptions.Item>
            </>
          ) : (
            <Descriptions.Item label="Montant" className="item-label">
              {reglement.data.montant}
            </Descriptions.Item>
          )}
        </Descriptions>
      ),
    },

    {
      selector: "s__ref",
      right: true,
      grow: 1,

      format: (reglement) => (
        <Popconfirm
          title={`Voulez vous vraiment supprimer ce réglement?`}
          onConfirm={() => deleteReglement(reglement.id)}
          okText="Oui"
          placement="left"
          cancelText="Non"
        >
          <Button type="link" icon={<BiTrash />} />
        </Popconfirm>
      ),
    },
  ];

  const onChangeClient = (option) => {
    if (option == "Tout") {
      setId(-1);
      toGetData(0, perPage, -1);

      return;
    }

    const l__id = clients.filter((el) => el.name == option)[0].id;
    setId(l__id);
    toGetData(0, perPage, l__id);
  };

  return (
    <div id="vente">
      <Loading show={show} color="red" showSpinner={true} />
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={5}>LISTE DES RÉGLEMENTS</Typography.Title>
          </Col>
          <Col span="6" style={{ textAlign: "right" }} justify="end">
            <Form.Item label="Réf,note,client/mf affiché">
              <Input.Search
                onSearch={onSearch}
                placeholder="Recherche par référence, client, total..."
                enterButton
              />
            </Form.Item>
          </Col>

          {/* <Col span="4">
            <Form.Item label="Date">
              <Select
                showSearch
                onSelect={onChangeDate}
                style={{ width: "100%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                {/* <Option value="4">Cette année</Option>*
                <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && (
              <DatePicker
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBLS(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
            {displayDateRange && (
              <DatePicker.RangePicker
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBLS(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
          </Col> */}

          {/* <Col span="4">
            <Form.Item label="Client">
              <Select
                showSearch
                onSelect={onChangeClient}
                style={{ width: "80%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                {clients.map((c) => (
                  <Option value={c.name}>{c.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          {/* <Col span="4">
            <Form.Item label="Utilisateurs">
              <Select
                showSearch
                onSelect={onChangeUsers}
                style={{ width: "80%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                {users.map((u) => (
                  <Option value={u.username}>{u.username}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
      </Form>{" "}
      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={reglements}
        pagination
        selectableRows
        noHeader
        striped
        selectableRowsHighlight
        fixedHeader
        persistTableHead
        noDataComponent={<span>Pas de réglements trouvés</span>}
        contextMessage={{
          singular: "élément",
          plural: "éléments",
          message: "séléctionné(s)",
        }}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
      />
      <Skeleton active loading={loading} />
      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search
          toUpdateData={toUpdateData}
          fields={fields}
          list={reglementsAll}
        />
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(null, mapDispatchToProps)(ReglementsListClient);
