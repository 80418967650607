import { useEffect, useState } from "react";
import {
  Skeleton,
  Button,
  Modal,
  Typography,
  Image,
  Divider,
  Empty,
  Row,
  Col,
  Input,
  Form,
  Select,
} from "antd";
import CAxios from "../../Utils/CAxios";
import {
  PlusOutlined,
  FileTextOutlined,
  MinusOutlined,
} from "@ant-design/icons";

const TecdocLiaison = ({ selectedArticle, setTecdocLiaison }) => {
  const [form] = Form.useForm();

  const [articlesTecdoc, setArticlesTecdoc] = useState([]);
  const [articlesTecdocAll, setArticlesTecdocAll] = useState([]);
  const [brands, setBrands] = useState([]);
  const [groups, setGroups] = useState([]);
  const [origines, setOrigines] = useState([]);
  const [details, setDetails] = useState([]);
  const [displayBigResult, setDisplayBigResult] = useState(false);
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState("");
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [article, setArticle] = useState();
  const [pageTecdoc, setPageTecdoc] = useState(1);
  const { Option } = Select;

  useEffect(() => {
    toGetArticlesTecdoc(
      null != selectedArticle.s__reference
        ? selectedArticle.s__reference
        : selectedArticle.reference,
      "",
      1
    );

    form.setFieldsValue({
      searchWordTecdoc:
        null != selectedArticle.s__reference
          ? selectedArticle.s__reference
          : selectedArticle.reference,
    });
  }, [selectedArticle]);

  const toGetArticleDetails = async (article) => {
    setLoadingModal(true);
    setDetailModalVisible(true);

    const { data } = await CAxios.post(`/api/tecdoc/detail`, {
      articleId: article.articleId,
    });

    setArticle(article);
    setOrigines(data.origine);
    setDetails(data.details);
    setImage(data.docId);
    setLoadingModal(false);
  };

  const toGetArticlesTecdoc = async (search, brandId, pagenb) => {
    setArticlesTecdoc([]);
    setArticlesTecdocAll([]);
    setLoading(true);
    const { data } = await CAxios.post(`/api/tecdoc/all`, {
      search: search,
      page: pagenb,
      brandId: brandId,
    });

    if (brandId == "") {
      form.setFieldsValue({ marque: "Tout", groupe: "Tout" });
    }
    if (data.status && data.status == 413) {
      //setDisplayBigResult(true);
    }

    setArticlesTecdoc(data.articles);
    setArticlesTecdocAll(data.articles);
    setLoading(false);

    if (data.brands.length > 0) {
      setBrands(data.brands);
      setGroups(data.groups);
      setLoading(false);
    }
  };

  const toCloseModal = () => {
    setDetailModalVisible(false);
    setArticle(null);
    setOrigines([]);
    setDetails([]);
    setImage("");
    setLoadingModal(false);
  };

  const toBindTecdoc = async (article, todelete) => {
    if (todelete) {
      var json = {
        id: selectedArticle.id,
        referenceTecdoc: "",
        info: [],
        name: article.articleName,
      };
      CAxios.post(`/api/tecdoc/update`, json).then(() => {
        setTecdocLiaison(false, json);
      });
    } else {
      await CAxios.post(`/api/tecdoc/detail`, {
        articleId: article.articleId,
      }).then((res) => {
        var json = {
          id: selectedArticle.id,
          referenceTecdoc: article.articleNo,
          info: res.data,
          name: article.articleName,
        };
        CAxios.post(`/api/tecdoc/update`, json).then(() => {
          setTecdocLiaison(false, json);
        });
      });
    }
  };

  const onChangeMarque = (e) => {
    if (e == "Tout") {
      toGetArticlesTecdoc(form.getFieldValue("searchWordTecdoc"), "", 1);
    } else {
      var newArray = brands.filter(function (item) {
        return item.brandName == e;
      });

      toGetArticlesTecdoc(
        form.getFieldValue("searchWordTecdoc"),
        newArray[0].brandNo,
        1
      );
    }
  };

  const onChangeGroup = (e) => {
    if (e == "Tout") {
      setArticlesTecdoc(articlesTecdocAll);
    } else {
      var newArray = articlesTecdocAll.filter(function (item) {
        return item.articleName == e;
      });

      setArticlesTecdoc(newArray);
    }
  };
  // const onChangeMarque = (e) => {
  //   if (e == "Tout") {
  //     setArticlesTecdoc(articlesTecdocAll);
  //   } else {
  //     var n = e.indexOf("(");
  //     e = e.slice(0, n);

  //     var newArray = articlesTecdocAll.filter(function (item) {
  //       return item.brandName == e;
  //     });

  //     setArticlesTecdoc(newArray);
  //   }
  // };

  const onSearchTecdoc = (value) => {
    setLoading(true);
    setArticlesTecdoc([]);
    setArticlesTecdocAll([]);
    toGetArticlesTecdoc(value, "", 1);
    setDisplayBigResult(false);
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        style={{ padding: 10 }}
        initialValues={{ remember: true }}
      >
        <Row gutter={24} style={{ marginTop: "0.5vh" }}>
          <>
            <Col md={8}>
              <Form.Item
                style={{ marginBottom: 0 }}
                label="Rec tecdoc"
                name="searchWordTecdoc"
              >
                <Input.Search
                  onSearch={onSearchTecdoc}
                  placeholder="Recherche..."
                  enterButton
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Marque"
                name="marque"
                style={{ marginBottom: 0 }}
              >
                <Select showSearch onChange={(e) => onChangeMarque(e)}>
                  <option value="Tout">Tout</option>
                  {brands &&
                    brands.map((brand, j) => (
                      <Option value={brand.brandName} key={j}>
                        {brand.brandName + "(" + brand.number + ")"}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Groupe"
                name="groupe"
                style={{ marginBottom: 0 }}
              >
                <Select showSearch onChange={(e) => onChangeGroup(e)}>
                  <option value="Tout">Tout</option>
                  {groups.map((group, j) => (
                    <Option value={group.group} key={j}>
                      {group.group + "(" + group.number + ")"}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        </Row>
      </Form>
      <div
        className="table-wrapper auto--overflow"
        style={{ marginTop: "2vh" }}
      >
        {<Skeleton active loading={loading} />}

        {null != articlesTecdoc && articlesTecdoc.length > 0 && (
          <table className="ant-table" style={{ width: "100%" }}>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell">Référence</th>
                <th className="ant-table-cell">Article</th>
                <th className="ant-table-cell">Marque</th>
                <th className="ant-table-cell">Prix U TTC</th>
                <th className="ant-table-cell">Stock</th>
                <th className="ant-table-cell"></th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              {!loading &&
                null != articlesTecdoc &&
                articlesTecdoc.length > 0 &&
                articlesTecdoc.map((element, i) => (
                  <tr
                    key={i}
                    className={
                      element.inStock
                        ? "inStock"
                        : i % 2 != 0 && "auto--background-grey"
                    }
                  >
                    <td className="ant-table-cell">
                      {element.inStock
                        ? element.data.s__reference
                        : element.articleNo}
                    </td>

                    <td className="ant-table-cell">{element.articleName}</td>
                    <td className="ant-table-cell">{element.brandName}</td>

                    {null != element.data ? (
                      <>
                        <td className="ant-table-cell">
                          {element.data.l__prixVenteTTC.toFixed(3)}
                        </td>
                        <td className="ant-table-cell">
                          <span
                            className={
                              element.data.stock <= 0
                                ? "auto--color-danger"
                                : element.data.stock < element.data.l__seuil
                                ? "auto--color-warning"
                                : "auto--color-success"
                            }
                          >
                            {element.data.stock}
                          </span>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="ant-table-cell"></td>{" "}
                        <td className="ant-table-cell"></td>
                      </>
                    )}
                    <td
                      className="ant-table-cell"
                      style={{ display: "inline-flex", float: "right" }}
                    >
                      <Button
                        onClick={(e) =>
                          toBindTecdoc(
                            element,
                            element.articleNo ==
                              selectedArticle.s__reference_tecdoc
                          )
                        }
                        disabled={
                          element.articleNo !=
                            selectedArticle.s__reference_tecdoc &&
                          element.inStock
                        }
                        icon={
                          element.articleNo ==
                          selectedArticle.s__reference_tecdoc ? (
                            <MinusOutlined />
                          ) : (
                            <PlusOutlined />
                          )
                        }
                      >
                        {element.articleNo ==
                        selectedArticle.s__reference_tecdoc
                          ? "Supprimer la liaison"
                          : "Lier"}
                      </Button>

                      <Button
                        icon={<FileTextOutlined />}
                        onClick={(e) => toGetArticleDetails(element)}
                        style={{ marginLeft: 1 }}
                      ></Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {/* {displayBigResult && !loading && (
          <Empty description="La recherche contient beaucoup de résultats. Merci d'affiner votre recherche!"></Empty>
        )} */}
        {!loading && !displayBigResult && articlesTecdoc.length == 0 && (
          <Empty description="Aucun article disponible"></Empty>
        )}
        <Modal
          className="smtv-modal"
          title={
            null != article && article.articleNo + " " + article.articleName
          }
          visible={detailModalVisible}
          maskClosable={false}
          onCancel={(e) => toCloseModal()}
          footer={[
            <Button key="back" onClick={(e) => setDetailModalVisible(false)}>
              Fermer
            </Button>,
          ]}
        >
          <Skeleton active avatar loading={loadingModal} />

          <Divider>Caractéristiques de l'article</Divider>
          <table className="ant-table">
            <tbody className="ant-table-tbody">
              {details.map((o, x) => (
                <tr key={x}>
                  <td className="ant-table-cell">
                    <Typography.Title level={5}>
                      <strong>{o.name}</strong>
                    </Typography.Title>
                  </td>

                  <td className="ant-table-cell">
                    <Typography.Text>{o.value}</Typography.Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Divider>References d'origine</Divider>

          <table className="ant-table">
            <tbody className="ant-table-tbody">
              {origines.map((o, x) => (
                <tr key={x}>
                  <td className="ant-table-cell">
                    <Typography.Title level={5}>
                      <strong>{o.name}</strong>
                    </Typography.Title>
                  </td>

                  <td className="ant-table-cell">
                    <Typography.Link>{o.value}</Typography.Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Divider>Images</Divider>
          {image != "" && (
            <Image
              src={
                "https://webservice.tecalliance.services/pegasus-3-0/documents/20197/" +
                image
              }
            />
          )}
        </Modal>
      </div>
    </>
  );
};

export default TecdocLiaison;
