import {
  Button,
  Descriptions,
  Typography,
  Skeleton,
  Row,
  Col,
  Empty,
  Popconfirm,
  Modal,
  Input,
  Form,
} from "antd";
import { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/fr";
import { toDeleteReglement, toGetReglements } from "../APIs";
import { customFilter, toConvertDate } from "../../../Utils/Utils";
import { CloseOutlined, FilterOutlined } from "@ant-design/icons";
import Search from "../../../Utils/Search";
moment.updateLocale("fr", localization);

const ReglementsList = (props) => {
  const [reglements, setReglements] = useState([]);
  const [reglementsAll, setReglementsAll] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);

  const fields = [
    { name: "Référence", field: "s__ref", type: "varchar" },
    { name: "Montant", field: "montant", type: "number" },
    { name: "Date ", field: "date", type: "date" },
  ];

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    const updatedList = customFilter(e.target.value, reglementsAll, [
      "providerName",
      "montant",
      "s__ref",
      "facture",
      "date",
      "banque",
      "nom",
      "num",
    ]);
    setReglements(updatedList);
  };
  useEffect(() => {
    toGetReglements().then((data) => {
      for (let index = 0; index < data.length; index++) {
        data[index]["montant"] = data[index].data.montant;
        data[index]["facture"] = data[index].ref;
        data[index]["date"] =
          null != data[index].data.date
            ? moment(data[index].data.date).format("YYYY/MM/DD")
            : "";
        data[index]["banque"] =
          null != data[index].data.banque ? data[index].data.banque : "";
        data[index]["nom"] =
          null != data[index].data.nom ? data[index].data.nom : "";
        data[index]["num"] =
          null != data[index].data.num ? data[index].data.num : "";
      }
      setReglements(data);
      setReglementsAll(data);
      setLoading(false);
    });
  }, []);

  const toUpdateData = (values) => {
    setReglements(values);
    setFilterModalVisible(false);
  };

  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";
        break;
      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";
      case 5:
        return "Retenu à la source";
      case 5:
        return "Contre remboursement";

      default:
        break;
    }
  };

  const deleteReglement = (id) => {
    toDeleteReglement(id).then(() => {
      let newReglements = reglements.filter((e) => e.id != id);
      setReglements(newReglements);
    });
  };

  return (
    <div id="achat">
      <Row className="auto--custom-header">
        <Col span="8">
          <Typography.Title level={3}>Liste des reglements</Typography.Title>
        </Col>
        <Col span="7" style={{ textAlign: "right" }}></Col>
        <Col span="5">
          <Form.Item label="Recherche">
            <Input.Search
              onInput={onSearch}
              value={searchValue}
              placeholder="Recherche par référence, fournisseur, total..."
              enterButton
            />
          </Form.Item>
        </Col>
        <Col span="3" style={{ textAlign: "right" }}>
          <Button
            type="link"
            onClick={(e) => setFilterModalVisible(true)}
            icon={
              <img
                src="/images/filterIconVente.svg"
                style={{ width: "3vh", height: "3vh" }}
              />
            }
            style={{ marginRight: "5px" }}
          ></Button>
        </Col>
      </Row>

      <Row>
        <Col span="24">
          <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Référence</th>
                  <th className="ant-table-cell">Facture</th>
                  <th className="ant-table-cell"></th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {reglements &&
                  reglements.map((reglement, i) => (
                    <tr key={i}>
                      <td className="ant-table-cell">{reglement.s__ref}</td>
                      <td className="ant-table-cell">{reglement.ref}</td>
                      <td className="ant-table-cell">
                        <Descriptions
                          column={5}
                          style={{ padding: "4px 8px" }}
                          bordered
                          size="small"
                        >
                          {reglement.type == 1 || reglement.type == 2 ? (
                            <>
                              <Descriptions.Item className="item-label">
                                <b>{getReglementType(reglement)}</b>
                              </Descriptions.Item>
                              <Descriptions.Item
                                label="Nom"
                                className="item-label"
                              >
                                {reglement.data.nom}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label="Date"
                                className="item-label"
                              >
                                {toConvertDate(reglement.data.date)}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label="Banque"
                                className="item-label"
                              >
                                {reglement.data.banque}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label="Montant"
                                className="item-label"
                              >
                                {reglement.data.montant}
                              </Descriptions.Item>
                            </>
                          ) : (
                            <Descriptions.Item
                              label="Montant"
                              className="item-label"
                            >
                              {reglement.data.montant}
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      </td>

                      <td className="ant-table-cell" style={{ float: "right" }}>
                        <Popconfirm
                          title={`Voulez vous vraiment supprimer ce réglement?`}
                          onConfirm={() => deleteReglement(reglement.id)}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <Button icon={<BiTrash />} />
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {reglements.length < 1 && (
              <Empty
                style={{ textAlign: "center" }}
                description="Aucun réglements disponible"
              ></Empty>
            )}
          </div>
        </Col>
      </Row>
      <Skeleton active loading={loading} />

      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search
          toUpdateData={toUpdateData}
          fields={fields}
          list={reglementsAll}
        />
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(null, mapDispatchToProps)(ReglementsList);
