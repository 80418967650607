import Modal from "antd/lib/modal/Modal";
import { Button, Row, Col } from "antd";
import { toGetBL, toPrintBL } from "../APIs";
import { PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../Utils/Utils";
import { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";

const BonLivraisonModal = ({ id, onClose, entete }) => {
  const [blItems, setBlItems] = useState(null);
  const [bl, setBl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (null != id) {
      setLoading(true);

      toGetBL(id).then((res) => {
        setBl(JSON.parse(res.bonlivraison));
        setLoading(false);
      });
    }
  }, [id]);

  const printBl = (e) => {
    e.preventDefault();
    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }

    toPrintBL(bl.id, titre1, entete);
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={id != null}
      onCancel={onClose}
      title="Details du bon de livraison"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>,
        <Button
          key="submit"
          type="primary"
          icon={<PrinterOutlined />}
          onClick={(e) => printBl(e)}
          onContextMenu={(e) => printBl(e)}
        >
          Imprimer
        </Button>,
      ]}
    >
      <LoadingScreen
        loading={loading}
        bgColor="#f5f8fa"
        spinnerColor="#B9D9EB"
        textColor="#2D2926"
      >
        {bl != null && (
          <>
            <h1 style={{ textAlign: "center" }}>Bon Livraison</h1>
            <Row>
              <Col className="info-box" md={11}>
                <p>
                  <b>Ref</b> : {bl.s__ref}
                </p>
                <p>
                  <b>Date</b> : {toConvertDate(bl.dt__createdDate)}
                </p>
                <p>
                  <b>Chargé client</b> :
                  {bl.l__createdBy && bl.l__createdBy.username}
                </p>
              </Col>
              <Col className="info-box" md={11}>
                <h1>Client</h1>
                <p>
                  <b>Nom</b> :
                  {bl.displayableName
                    ? bl.displayableName
                    : bl.client && bl.client.s__name}
                </p>
                <p>
                  <b>Note</b> :{bl.s__note}
                </p>
                <p>
                  <b>Tel</b> : {bl.client && bl.client.s__phoneNumber}
                </p>
              </Col>
            </Row>
            <h2>Articles</h2>
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell" style={{ width: "8%" }}>
                    Ref
                  </th>
                  <th className="ant-table-cell">Désignation</th>
                  <th className="ant-table-cell" style={{ width: "8%" }}>
                    Prix Unitaire HT
                  </th>
                  <th className="ant-table-cell" style={{ width: "8%" }}>
                    Prix Unitaire TTC
                  </th>
                  <th className="ant-table-cell" style={{ width: "8%" }}>
                    Quantité
                  </th>
                  <th className="ant-table-cell" style={{ width: "8%" }}>
                    Remise
                  </th>
                  <th className="ant-table-cell" style={{ width: "8%" }}>
                    Prix Total
                  </th>
                  <th className="ant-table-cell" style={{ width: "5%" }}>
                    TVA
                  </th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {null != bl &&
                  bl.items.map((item) => (
                    <tr>
                      <td className="ant-table-cell" style={{ width: "8%" }}>
                        {item.article.s__reference}
                      </td>
                      <td className="ant-table-cell">
                        {item.article.s__firstTitle}
                      </td>
                      <td className="ant-table-cell" style={{ width: "12%" }}>
                        {item.d__unitPriceHT.toFixed(3)}
                      </td>
                      <td className="ant-table-cell" style={{ width: "12%" }}>
                        {item.d__unitPriceTTC.toFixed(3)}
                      </td>
                      <td className="ant-table-cell" style={{ width: "10%" }}>
                        {item.d__quantity}
                      </td>
                      <td className="ant-table-cell" style={{ width: "10%" }}>
                        {parseFloat(item.d__discount).toFixed(2)} %
                      </td>
                      <td className="ant-table-cell" style={{ width: "12%" }}>
                        {item.d__total.toFixed(3)} Dt
                      </td>
                      <td className="ant-table-cell" style={{ width: "6%" }}>
                        {item.d__tva} %
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div style={{ textAlign: "right", marginTop: 20 }}>
              <div
                style={{
                  display: "inline-block",
                  textAlign: "right",
                }}
              >
                <p>
                  <b>Tota HT</b> : {(bl.d__total - bl.d__tva).toFixed(3)} DT
                </p>
                <p>
                  <b>TVA</b> : {bl.d__tva.toFixed(3)} DT
                </p>
                <p>
                  <b>Remise Totale</b> : {bl.d__discount.toFixed(3)} DT
                </p>
                <p>
                  <b>Net à Payer</b> : {bl.d__total.toFixed(3)} DT
                </p>
              </div>
            </div>
          </>
        )}
      </LoadingScreen>
    </Modal>
  );
};

export default BonLivraisonModal;
