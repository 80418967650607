import CAxios from "../../Utils/CAxios";

export const toGetTotalHT = async (date) => {
  const { data } = await CAxios.post(`/api/user/bl`, { date: date });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetTotalTTC = async (date, entityId) => {
  const { data } = await CAxios.post(`/api/user/blttc`, {
    date: date,
    entityId: entityId,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetTotalUser = async (start, end) => {
  const { data } = await CAxios.post(`/api/user/total`, {
    start: start,
    end: end,
  });
  return data.success && data.success.list ? data.success.list : null;
};
